<script setup lang="ts">
import type { User } from "~/types/user"

const links = useDashboardMenu()
const subscription = useSubscription()

const user = useStrapiUser<User>();
const { logout } = useStrapiAuth();
const isMenuOpen = ref(false); // State to toggle the menu

function isLoggedIn() {
  return !!user.value;
}

function onSignOut() {
  logout();
  navigateTo('/login');
}

function toggleMenu() {
  isMenuOpen.value = !isMenuOpen.value; // Toggle the menu state
}
</script>

<template>
  <div class="sticky top-0 z-10 w-full bg-white-10 shadow-md">
    <!-- Header -->
    <div class="flex justify-between items-center p-4">
      <NuxtLink to="/" class="text-black flex items-center">
        <svg viewBox="0 0 117.724 117.722" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
          <g id="SvgjsG1012" featurekey="HKaMnE-0" transform="matrix(1.428547978401184, 0, 0, 1.428547978401184, -10.420923388316083, -7.202758896391963)" fill="#000">
            <g xmlns="http://www.w3.org/2000/svg" transform="matrix(1, 0, 0, 1, -1.358372, -3.375095)">
              <path d="M86.834,90.834H33.166c-2.209,0-4-1.791-4-4V61.5c0-2.209,1.791-4,4-4h33.668c2.209,0,4,1.791,4,4v10c0,2.209-1.791,4-4,4   s-4-1.791-4-4v-6H37.166v17.334h45.668V56.69L49.976,19.206l-32.81,36.819v30.809c0,2.209-1.791,4-4,4s-4-1.791-4-4V54.502   c0-0.981,0.361-1.929,1.014-2.661l36.834-41.336c0.763-0.856,1.816-1.368,3.002-1.339c1.147,0.005,2.236,0.501,2.992,1.363   l36.834,42.02c0.64,0.729,0.992,1.667,0.992,2.637v31.648C90.834,89.043,89.043,90.834,86.834,90.834z"/>
            </g>
          </g>
        </svg>
        <span class="text-lg ml-2" style="font-family: 'Radio Canada Big';color:#000;font-weight:500;">Boligkartoteket</span>
      </NuxtLink>

      <button class="md:hidden text-black" @click="toggleMenu">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </button>

      <ul v-if="isLoggedIn()" class="flex text-sm text-black hidden md:flex">
        <li class="py-1 px-2 hover:underline border-r border-blue-30">
          <NuxtLink to="/administration/dashboard">
            <PhClipboard size="20" class="inline-block mr-1" />Mine boliger
          </NuxtLink>
        </li>

        <li class="py-1 px-2 hover:underline cursor-pointer xs-hidden md-flex" @click="onSignOut">
          <PhSignOut size="20" class="inline-block mr-1" />Log ud
        </li>
      </ul>
      <ul v-else class="flex text-sm text-white hidden md:flex">
        <li class="py-1 px-2 hover:underline">
          <NuxtLink to="/login">
            <PhSignIn size="20" color="black" class="inline mr-1" />Log ind
          </NuxtLink>
        </li>
        <li class="py-1 px-2 hover:underline">
          <NuxtLink to="/signup">
            <PhUserPlus size="20" color="black" class="inline mr-1" />Opret
          </NuxtLink>
        </li>
      </ul>
    </div>

    <!-- Sliding Menu -->
    <transition name="slide-down">
      <div v-if="isMenuOpen" class="bg-white-10 w-full p-4 shadow-md">
        <nav>
          <ul v-if="isLoggedIn()" class="text-sm text-black">
            <li class="py-2 hover:underline" v-for="link in links">
              <NuxtLink :to="link.path">
                <component :is="link.icon" :size="16" class="inline-block mr-1" />{{ link.name }}
              </NuxtLink>
            </li>
            <li class="py-2 hover:underline cursor-pointer" @click="onSignOut">
              <PhSignOut size="16" class="inline-block mr-2" />Log ud
            </li>
          </ul>
          <ul v-else class="text-sm text-black">
            <li class="py-2 hover:underline">
              <NuxtLink to="/login">
                <PhSignIn size="16" color="black" class="inline" />
                Log ind
              </NuxtLink>
            </li>
            <li class="py-2 hover:underline">
              <NuxtLink to="/signup">
                <PhUserPlus size="16" color="black" class="inline" />
                Opret
              </NuxtLink>
            </li>
          </ul>
        </nav>
      </div>
    </transition>

    
  </div>
</template>

<style>
/* Sliding animation */
.slide-down-enter-active,
.slide-down-leave-active {
  transition: max-height 0.3s ease-out;
}

.slide-down-enter-from,
.slide-down-leave-to {
  max-height: 0;
  overflow: hidden;
}

.slide-down-enter-to,
.slide-down-leave-from {
  max-height: 800px; /* Adjust based on your menu content */
}
</style>
